<template>
  <section class="page-market-finalize">
    <b-overlay
        :show="loading"
        no-center
        variant="transparent"
        rounded="sm"
    >
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row v-if="!loading && paymentStatus">
        <b-col
            offset-md="3"
            md="6"
            sm="12"
        >
          <div class="payment-result">
            <b-img :src="iconPaymentSuccess" />
            <div class="fs-22 fw-600 text-center color-black mt-3">
              {{ $t('Congratulations') }}! <br>
              {{ $t('Your purchase is successful') }}.
            </div>
            <div class="fs-14 color-gray text-center mt-05">
              {{ $t('You can go to the Discover page and start calling') }}!
            </div>
            <router-link
                :to="{name: 'discover'}"
                style="width: 100%"
            >
              <b-button
                  class="mt-2 py-1"
                  variant="primary"
                  block
              >
                {{ $t('Go to Discover') }}
              </b-button>
            </router-link>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !paymentStatus">
        <b-col
            offset-md="3"
            md="6"
            sm="12"
        >
          <div class="payment-result">
            <b-img :src="iconPaymentFailed" />
            <div class="fs-22 fw-600 text-center color-black mt-3">
              {{ $t('We are sorry') }}... <br>
              {{ $t('An error occurred during the operation') }}.
            </div>
            <div class="fs-14 color-gray text-center mt-05">
              {{ $t('İşlemini bir hata sebebiyle gerçekleştiremedik. Hemen tekrar deneyebilirsin.') }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BButton, BCol, BImg, BOverlay, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCol,
    BImg,
    BOverlay,
    BRow,
  },
  data() {
    return {
      iconDiamonds1: require('@/assets/images/icons/diamonds-1.svg'),
      iconPaymentSuccess: require('@/assets/images/icons/payment-success.svg'),
      iconPaymentFailed: require('@/assets/images/icons/payment-failed.svg'),
      paymentStatus: false,
      product: {
        credits: 500,
      },
      ykbPosInfoFromServerKey: process.env.VUE_APP_YKB_POS_INFO_FROM_SERVER_KEY,
    }
  },
  computed: {
    loading() {
      return this.$store.getters['payment/loading']
    },
  },
  created() {
    try {
      const base64Code = this.$route.query.c
      const buff = new Buffer(base64Code, 'base64')
      const posnetData = JSON.parse(buff.toString('utf8'))
      // const posnetData = this.$cookies.get(this.ykbPosInfoFromServerKey)
      if (!posnetData || !localStorage.getItem('LAST_PAYMENT_TRANSACTION_ID_KEY')) {
        window.parent.postMessage({
          type: 'payment.result',
          data: {
            type: 'danger',
            message: this.$t('An error occurred during the payment process. Please try again.'),
          },
        }, '*')
        // this.$router.push({ name: 'market' })
      } else if (posnetData) {
        const transaction = {
          merchantPacket: posnetData.MerchantPacket,
          bankPacket: posnetData.BankPacket,
          sign: posnetData.Sign,
          ccPrefix: posnetData.CCPrefix,
          tranType: posnetData.TranType,
          couponCode: localStorage.getItem('LAST_COUPON_CODE', null),
          // amount: posnetData.Amount,
          // xid: posnetData.Xid,
          // merchantId: posnetData.MerchantId,
        }

        this.$store.dispatch('payment/setLoading', true)
        gtag(
            'event',
            'conversion',
            {
              send_to: 'AW-805828675/TF_ICNfKv4EBEMPwn4AD',
              value: parseFloat(localStorage.getItem('LAST_PAYMENT_AMOUNT')),
              currency: 'TRY',
              transaction_id: localStorage.getItem('LAST_PAYMENT_TRANSACTION_ID_KEY'),
            },
        )
        window.parent.postMessage({
          type: 'payment.result',
          data: {
            type: 'success',
            transaction: {
              data: transaction,
              ykbPosInfoFromServerKey: this.ykbPosInfoFromServerKey,
            },
          },
        }, '*')
      } else {
        window.parent.postMessage({
          type: 'payment.result',
          data: {
            type: 'danger',
            message: this.$t('An error occurred during the payment process. Please try again.'),
          },
        }, '*')
      }
    } catch (e) {
      window.parent.postMessage({
        type: 'payment.result',
        data: {
          type: 'danger',
          message: this.$t('An error occurred during the payment process. Please try again.'),
        },
      }, '*')
      // this.$router.push({ name: 'market' })
    }
  },
  methods: {
    deleteCookie(name) {
      if (this.$cookies.get(name)) {
        this.$cookies.remove(name)
        // document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
      }
    },
    getCookie(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()
      return null
    },
  },
}
</script>

<style lang="scss" scoed>
.payment-result {
  background: #F5F8FD;
  border-radius: 64px;
  padding: 85px 148px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
